import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import { router } from "./router/routes";
import "./assets/styles/tailwind.css";
import { HttpClient } from "./services/HttpClient/HttpClient";
import { Constants } from "./services/Constants/Constants";
import { UserSession } from "./services/UserSession/UserSession";
import axios from "axios";
import Globals from "./components/base/globals";
import { msalPlugin } from "@/auth/msalPlugin";
import { msalInstance } from "@/auth/authConfig";
import { type AuthenticationResult, EventType } from "@azure/msal-browser";
import { Chart, registerables } from "chart.js";
import sentryInit from "./configs/sentry";

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// create single axios instance that will be used by the entire app
// export const axios = AxiosStatic.create();

export let userSessionService: UserSession;

msalInstance
  .handleRedirectPromise()
  .then((responseToken) => {
    const app = createApp(App);
    
    // init sentry
    // sentryInit(app, router);

    app.use(Globals);

    if (responseToken) localStorage.setItem("b2cToken", responseToken.idToken);

    userSessionService = new UserSession(router);

    if (userSessionService.isAuthenticated) {
      // initialize services
      const httpClient = new HttpClient(axios);
      const constantsService = new Constants(httpClient);
      const pinia = createPinia();

      //fetch constants then initialize the app
      constantsService.fetchConstants().then(() => {
        app.use(router);
        app.use(pinia);
        app.use(msalPlugin, msalInstance);
        app.provide("httpClient", httpClient);
        app.provide("constants", constantsService);
        app.provide("userSession", userSessionService);
        router.isReady().then(() => {
          app.mount("#app");

          //register chart js
          Chart.register(...registerables);
        });
      });
    } else {
      //initialize the app with minimum configs
      app.use(router);
      app.use(msalPlugin, msalInstance);
      app.provide("userSession", userSessionService);
      router.isReady().then(() => app.mount("#app"));
    }
  })
  .catch((err) => console.log("EROARE", err));
